.container {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(to bottom, var(--black) 65%, var(--primary));
  color: var(--white);
}

.navigations{
  display: block;
  font-size: 1.2rem;
  text-decoration: underline;
  line-height: 1;
  margin-block-end: 0.625rem;
}

.socials_container{
  display: flex;
  align-items: center;
  margin-block-start: 1.4rem;
  > span {
    margin-inline-end: 1rem;
  }
  @media screen and (max-width: 567px){
    justify-content: center;
  }
}

.socials_wrapper{
  display: flex;
  align-items: center;
  gap: 20px;
}

.social_link{
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  &::before{
    content: '';
    display: block;
    width: 56px;
    height: 5px;
    background-color: var(--white);
    margin-inline-end: 1.5rem;
    @media screen and (max-width: 567px) {
      content: none;
    }
  }
  &::after{
    position: absolute;
    top: 7px;
    left: 0;
    content: '';
    display: block;
    width: 56px;
    height: 5px;
    transform: translateX(-100%);
    background-color: var(--primary);
    margin-inline-end: 1.5rem;
    transition: transform 0.5s ease;
    @media screen and (max-width: 567px) {
        content: none;
    }
  }
  &:hover::after{
    transform: translateX(0);
  }
  @media screen and (max-width: 567px) {
    justify-content: center;
  }
}

.wrapper{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 567px) {
    flex-direction: column;
    > div:first-child{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 0.825rem;
      margin-block-end: 1rem;
      h2{
        margin: 0;
        text-align: center;
        br {
          display: none;
        }
      }
    }
    & > div:nth-child(2){
      padding: 0 2rem;
    }
    .links{
      width: 100%;
      margin-block-start: 2rem;
      > a{
        display: inline-block;
        margin: 0 1.5rem;
      }
    }
  }
}

.copyright{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 0.5rem 2rem;
  h6{
    margin: 0;
  }
}