
.section {
  min-height: calc(100vh - 190px);
  background-color: var(--black);
  color: var(--white);
  padding: 4.375rem 0 0;
}

.container{
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding-block-end: 4rem;
  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.video_bar{
  height: 100vh;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 350px;
  }
}

.content{
  position: relative;
  padding-inline-start: 3.25rem;
  padding-block-end: 3.25rem;
  max-width: 650px;
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 5%;
    left: 0;
    width: 1px;
    height: 130px;
    background-color: #d3d3d3;
  }
  &::after{
    content: '';
    display: block;
    position: absolute;
    top: 10%;
    left: -5px;
    width: 10px;
    height: 10px;
    background-color: var(--white);
    border-radius: 100%;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
    padding-inline-start: 1.5rem;
    a {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 567px) {
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
    p {
      text-align: justify;
      margin-block-end: 0;
    }
    &::before{
      left: 15px;
    }
    &::after{
      left: 10px;
    }
  }
}

.image_wrapper{
  position: relative;
  flex: 0 0 472px;
  height: 652px;
  border: 1px solid #707070;
  border-radius: 250px 250px 0px 250px;
  overflow: hidden;
  @media screen and (max-width: 992px) {
    flex: 0 0 375px;
    height: 550px;
  }
  @media screen and (max-width: 768px) {
    order: -1;
    height: 500px;
    margin-block-end: 4.5rem;
  }
  @media screen and (max-width: 567px) {
    flex: 0 0 325px;
    height: 450px;
  }
}

.image{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 10px;
  left: 10%;
  img {
    object-fit: contain;
  }
  @media screen and (max-width: 568px) {
    top: 5%;
    left: 10%;
    width: 330px;
    height: 450px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.four_point_star {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 26px;
  height: 26px;
  &::before{
    position: absolute;
    top: 0;
    left: 0;
    content: "\2726";
    font-size: 2rem;
    line-height: 1;
    color: var(--light-gray);
  }
}

.about, .mission{
  margin-block-start: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  text-align: justify;
  >div {
    position: relative;
    align-self: stretch;
    width: 48%;
    border-radius: 12px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    p{
      margin: 0;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
  @media screen and (max-width: 567px) {
    padding: 0 2rem;
    .about_banner, .mission_banner {
      height: 325px;
    }
  }
}


.stack{
  text-align: center;
  background: var(--white);
  color: var(--black);
  > p:first-child {
    margin-block-end: 5rem;
  }
  > :last-child{
    margin-block-start: 5rem;
    @media screen and (max-width: 768px) {
      margin-block-start: 2.5rem;
    }
  }
}
.features{
  position: relative;
}

.features_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.feature_item{
  flex: 0 0 33.3334%;
  padding: 1.2rem 1rem;
  border-left: 1px solid rgba(#dedede, 0.3);
  align-self: stretch;
  p{
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
  @media screen and (max-width: 567px) {
    border: none;
    padding-inline-end: 0;
    padding-inline-start: 0;
  }
}

.feature_image{
  position: absolute;
  top: 0;
  right: 0;
}

.features_content{
  position: relative;
  z-index: 10;
  @media screen and (max-width: 567px) {
    padding: 0 1rem;
  }
}

.testimonial_container{
  color: var(--black);
  background: var(--white);
  h2 {
    left: 50%;
    transform: translateX(-50%);
  }
}

.dots{
  position: relative;
  display: flex;
  align-items: center;
  margin-block-start: 4rem;
  h4{
    padding-inline-start: 3.125rem;
    margin: 0;
    font-weight: 400 !important;
  }
  &::before{
    content: '';
    display: block;
    width: 412px;
    border-bottom: 1px dashed #707070;
  }
  @media screen and (max-width: 567px) {
    margin-block-end: 2rem;
  }
}

.testimonial_bg{
  position: relative;
  padding-block-start: 5rem;

  @media screen and (max-width: 567px) {
    padding: 0 1rem;
  }
}

.testimonial_wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
  > div {
    flex: 0 0 30%;
    @media screen and (max-width: 768px) {
      flex: 0 0 100%;
    }
  }
}

.statistics{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.statistic_item{
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 33.3334%;
  padding: 5rem 0;
  @media screen and (max-width: 567px) {
    flex: 0 0 100%;
    justify-content: center;
  }

  &::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 54px;
    background-color: #ebebeb;
    @media screen and (max-width: 567px) {
     content: none;
    }
  }

  &::after{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -60%;
    width: 1px;
    height: 228px;
    background-color: #ebebeb;
    @media screen and (max-width: 567px) {
      left: 50%;
      transform: translateX(-50%);
      height: 50%;
      bottom: -30%;
    }
  }

  h6 {
    margin-inline-end: 1rem;
    margin-inline-start: 1rem;
    margin-block-end: 0;
  }
}

