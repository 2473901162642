.wrapper{
  position: relative;
}

.selected_label{
  font-size: 0.875rem;
  color: rgba(var(--black), 0.3);
  margin-block-end: 10px;
}

.selected{
  position: relative;
  display: block;
  width: 100%;
  height: 45px;
  line-height: 44px;
  padding-block-end: 10px;
  border-bottom: 1px solid var(--gray);
  color: var(--black-medium);
  font-size: 1rem;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
  &::before{
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 20px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #c3c3c3;

  }
}

.content_container{
  position: absolute;
  display: none;
  flex-direction: column;
  top: 68px;
  width: 100%;
  left: 0;
  background-color: var(--white);
  color: var(--black-medium);
  overflow: hidden;
  z-index: 20;
  &.active{
    display: flex;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  }
  & > button:not(:last-child) {
    border-bottom: 1px solid var(--black-medium);
  }
}

.item{
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.2;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
  transition: background-color .3s ease;
  &:hover{
    background-color: rgba(#9721ECFF, 0.4);
  }
}