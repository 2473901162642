.container{
  max-width: 1420px;
  margin: 3rem auto;
  padding: 0 1rem;
  a{
   margin: 0 0.5rem;
    text-decoration: underline;
    color: var(--error);
  }
  h5{
    font-size: 1.3rem;
    text-decoration: underline;
  }
  h2{
    scroll-padding-top: 100em;
  }
}

.divider{
  margin-block-end: 2.5rem;
}