
.container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
}

.title{
  flex-shrink: 0;
  align-self: flex-start;
}

.content{
  width: 100%;
  max-width: 610px;
  span{
    margin: 5rem 0;
  }
  h3 {
    margin: 0;
  }
}

.experience{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 7.8125rem;
  background-color: var(--white);
  color: var(--black);
  margin-block-start: 9rem;
  @media screen and (max-width: 768px) {
    padding: 5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem;
  }
}

.experience_title{
  flex: 0 0 50%;
  h2{
    position: relative;
  }
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    margin-block-end: 2rem;
  }
}

.experience_icon{
  position: absolute;
  right: 36px;
  bottom: -8px;
  display: block;
  width: 72px;
  height: 72px;
  background-image: url("../assets/points.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @media screen and (max-width: 768px) {
    right: 0;
  }
}


.static_content{
  flex: 0 0 40%;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.portfolio_title{
  & > span {
    right: auto;
  }
  @media screen and (max-width: 768px) {
    > span{
      right: auto;
      left: 0;
    }
  }
}

.freelancers_title{
  width: 100%;
  & > span {
    width: 320px;
    height: 50px;
    right: auto;
    left: 50%;
    bottom: -40px;
    transform: translateX(-50%);
  }
}

.freelance_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  margin-block-start: 5rem;
  @media screen and (max-width: 768px) {
    gap: 0;
  }
}

.freelance_item{
  flex: 0 0 30%;
  padding: 1rem 0 1rem 2.5rem;
  border-left: 1px solid #dedede;
  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.portfolio{
  background-color: var(--white);
  color: var(--black);
}

.portfolio_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    & > div{
      flex: 0 0 100%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem !important;
  }
}

.portfolio_content{
  flex: 0 0 45%;
}

.portfolio_items_container{
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  gap: 30px;
  @media screen and (max-width: 768px) {
    margin-block-start: 3.2rem;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.vertical_portfolio{
  > :first-child{
    margin-block-end: 30px;

  }
}