.header_container{
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6.25rem;
  height: 120px;
  background-color: var(--white);
  border-bottom: 1px solid var(--light-gray);
  z-index: 200;

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding-inline-start: 2rem;
  }

  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.four_point_star {
  position: absolute;
  bottom: -25px;
  right: 45%;
  width: 40px;
  height: 50px;
  z-index: 2;
  &::before{
    position: absolute;
    top: 0;
    left: 0;
    content: "\2726";
    font-size: 3rem;
    line-height: 1;
    color: var(--black);
  }
}

.nav-link {
  position: relative;
  color: var(--black);
  font-size: 1.125rem;
  font-family: 'Satoshi-Regular';
  transition: color 0.5s ease;
  padding: 0 1.0625rem;
  &:hover,
  &:focus,
  &:active{
    color: var(--primary);
  }
}

.nav-item_button{
  display: block;
  width: 198px;
  height: 70px;
  line-height: 68px;
  flex-shrink: 0;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--black);
  color: var(--black);
  border-radius: 25px;
  font-size: 1rem;
  transition: background-color .5s ease, color .5s ease;
  &:hover{
    background-color: var(--black);
    color: var(--white);
  }
}

.nav-item{
  display: block;
}

.bars {
  display: none;
  width: 35px;
  height: 5px;
  background-color: var(--black);
  margin-bottom: 3px;
  transition: 0.5s ease;
}

.burger_bar_container {
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &.active .bar1 {
    transform: rotate(-45deg) translate(-4px, 8px);
  }

  &.active .bar2 {
    opacity: 0;
  }

  &.active .bar3 {
    transform: rotate(45deg) translate(-3px, -8px);
  }

  &.active + .header_content {
    transform: translateX(0);
    z-index: 2;
  }
}

.header_brand{
  display: flex;
  align-items: flex-end;
  color: var(--black);
}

.header_brand_txt{
  align-self: flex-end;
  margin: 0.2rem;
  font-size: 0.825rem;
  line-height: 1;
}

.content_right{
  margin-inline-start: auto;
}

.header_content{
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

@media screen and (max-width: 1200px) {
  .header_container{
    height: 90px;
    padding: 0 3.125rem;
    nav{
      justify-content: flex-end;
    }
  }
  .nav-link{
    display: block;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .header_container{
    nav{
      justify-content: flex-end;
    }
  }

  .bars {
    display: block;
  }

  .header_content {
    position: fixed;
    justify-content: flex-start;
    flex-direction: column;
    padding: 80px 20px;
    top: 90px;
    right: 0;
    width: 350px;
    height: calc(100vh - 90px);
    background-color: var(--white);
    transition: transform 0.5s ease;
    transform: translateX(100%);
    z-index: 101;
    ul {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .nav-link{
    padding: 0;
    margin-block-end: 1rem;
  }
}