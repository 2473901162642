.wrapper{
    position: relative;
}

.label{
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(6px);
    font-size: 0.875rem;
    line-height: 1;
    color: var(--black-medium);
    transition: transform .4s ease, opacity .4s ease, color .4s linear;
}

.input{
    width: 100%;
    border-bottom: 1px solid #C3C3C3;
    font-size: 1rem;
    line-height: 44px;
    background-color: transparent;
    color: var(--black-medium);
    transition: border-color .5s ease;
    &:disabled{
        opacity: 0.3;
    }
    &:hover{
        border-color: var(--dark-gray);
    }
    &:focus{
        border-color: var(--primary);
        & + label {
            color: var(--primary);
            opacity: 1;
        }
    }

}

.iconBtn{
    width: 24px;
    height: 24px;
    color: rgba(0,0,0,.3);
    font-size: 0.875rem;
}

.focused{
    transform: translateY(-10px);
    top: 0;
}

.fullfilled{
    opacity: 0.3;
}

.error{
    color: var(--error);
}

.input_error{
    border-color: var(--error);
}