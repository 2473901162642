.button_primary{
  max-width: 218px;
  width: 100%;
  height: 70px;
  line-height: 68px;
  background-color: var(--primary);
  border: 1px solid var(--btn-border);
  color: var(--white);
  border-radius: 25px;
  font-size: 1rem;
  transition: background-color .5s ease, color .3s ease;
  &:hover{
    background-color: var(--white);
    color: var(--black);
  }
}

.button_dark{
  border-color: var(--black);
  background-color: var(--black);
  &:hover{
    background-color: transparent;
    border-color: var(--btn-border);
    color: var(--white);
  }
}

.read_more{
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 3.5rem;
  font-size: 0.875rem;
  line-height: 17px;
  background-color: transparent;
  &::before, &::after{
    content: '';
    display: block;
    max-width: 164px;
    width: 100%;
    min-width: 82px;
    height: 1px;
    background-color: var(--light-gray);
  }
}
