.container{
  display: flex;
}

.image_wrapper{
  position: relative;
  flex-grow: 1;
}

.form_container{
  flex: 0 0 54%;
  padding: 6.25rem 7.8rem;
  background-color: var(--white);
  color: var(--black);
  opacity: 1;
  border-top-right-radius: 12px;
  @media screen and (max-width: 1366px) {
    padding: 4.25rem 4.8rem;
  }
}

.icon_wrapper{
  display: flex;
  align-items: center;
  gap: 30px;
  color: var(--black);
  margin-block-end: 1rem;
}

.icon{
  width: 46px;
  height: 65px;
  border: 1px solid var(--primary);
  border-radius: 54px;
  line-height: 63px;
  font-size: 1.5rem;
  text-align: center;
  color: var(--primary);
  svg {
    width: 23px;
    height: 23px;
  }
}

.form{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  > div:not(:last-child) {
    flex:  0 0 48%;
  }
  > div:first-child {
    flex:  0 0 50%;
    > div:first-child{
      flex:  0 0 48%;
    }
  }
}

.additional_info{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.remember_user{
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  > input {
    width: 18px;
    height: 18px;
    background-color: transparent;
    opacity: 0;
    &:checked + span.custom_checkbox {
      background-color: var(--primary);
    }
  }
}

.custom_checkbox{
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 1px solid var(--primary);
  border-radius: 6px;
  pointer-events: none;
  transition: background-color .4s ease;
  &::before, &::after{
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 2px;
    height: 8px;
    background-color: var(--white);
    transform: rotate(45deg) translateY(-2px);
  }
  &::before{
    transform: rotate(-45deg);
    height: 4px;
    left: 4px;
    top: 8px;
  }
}