.container{
  background-color: var(--white);
  color: var(--black);
  text-align: center;
}


.portfolio_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-block-start: 4rem;
  & > div {
    flex: 0 0 22%;
    align-self: flex-start;
    @media screen and (max-width: 768px) {
      flex: 0 0 33.3334%;
    }
    @media screen and (max-width: 567px) {
      flex: 0 0 80%;
    }
  }
}

.banner{
  position: relative;
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--black);
  overflow: hidden;
  z-index: 40;
  padding-inline-start: 5rem;
  h1 {
    span {
      position: relative;
      display: inline-block;
      border: 2px dashed var(--white);
      &::before{
        content: '';
        display: inline-block;
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        height: 6px;
        background-color: var(--primary);
      }
    }
  }
  @media screen and (max-width: 567px) {
   & > div:first-child {
     position: absolute;
     display: flex;
     flex-direction: column;
      align-items: center;
     justify-content: flex-end;
     bottom: 10px;
     left: 0;
     width: 100%;
     height: 100%;
     padding: 0 2rem;
     z-index: 10;
   }
  }
  &::before{
    content: '';
    position: absolute;
    left: -180px;
    top: -280px;
    width: 600px;
    height: 600px;
    filter: blur(50px);
    background-image: linear-gradient(to right, #2603FB, #DC06FF);
    border-radius: 100%;
    z-index: -1;
  }
}

.banner_image{
  position: relative;
  width: 50vw;
  height: 80vh;
  @media screen and (max-width: 567px) {
    width: 100%;
    height: 50vh;
  }
}