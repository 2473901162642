:root{
  --primary: #9721EC;
  --black: #0B0B0B;
  --white: #ffffff;
  --error: #FF6C6C;
  --surface: #EBEBEB;
  --dark-gray: #313131;
  --dark-gray-700: #292929;
  --light-gray: #dedede;
  --gray: #8b8b8b;
  --black-medium: #171717;
  --bg-gradient: linear-gradient(to top, #0B0B0B 50%, #9721EC 150%);
  --btn-border: #8A38E3;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Satoshi-Regular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: var(--black);
  color: var(--white);
}

html{
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p{
  margin-block-start: 0;
}

h1, .h1 {
  font-size: 3.75rem;
  line-height: 84px;
  font-weight: bold;
  font-family: 'Satoshi-Black';
}

h2, .h2 {
  font-size: 3rem;
  line-height: 68px;
  font-weight: bold;
  font-family: 'Satoshi-Bold';
}

h3, .h3 {
  font-size: 1.875rem;
  line-height: 42px;
  font-weight: bold;
  font-family: 'Satoshi-Bold';
}

h4,.h4 {
  font-size: 1.5rem;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Satoshi-Light';
}

h6, .h6 {
  font-size: 1.125rem;
  line-height: 22px;
  font-weight: bold;
  font-family: 'Satoshi-Bold';
}

p{
  font-size: 1.5rem;
  line-height: 29px;
  font-weight: 400;
}

.fw-normal {
  font-weight: 400;
}

.ff-regular{
  font-family: 'Satoshi-Regular'
}

.text--line-through {
  text-decoration: line-through;
}

.text-medium-dark {
  color: var(--gray);
}

.spacing-xl {
  padding-block-start: 10rem;
  padding-block-end: 10rem;
}

.spacing {
  padding-block-start: 5rem;
  padding-block-end: 5rem;
}

.pt-xl {
  padding-block-start: 10rem;
}

.pb-xl {
  padding-block-end: 10rem;
}

.pt {
  padding-block-start: 5rem;
}

.pb {
  padding-block-end: 5rem;
}

.mb-5 {
  margin-block-end: 3.5rem;
}

.mt-5 {
  margin-block-start: 3.5rem;
}

.m-0 {
  margin: 0;
}

.ms-auto {
  margin-inline-start: auto;
}

input,button{
  appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: 0;
  color: inherit;
  background-color: transparent;
  padding: 0;
  margin: 0;
  &:hover,&:active,&:focus{
    box-shadow: none;
    outline: 0;
    background-color: transparent;
  }
}

section{
  min-height: 100vh;
}

.color--primary{
  color: var(--primary);
}

.container{
  margin: 0 auto;
}

.btn--dark{
  display: block;
  width: 198px;
  height: 70px;
  line-height: 68px;
  flex-shrink: 0;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--white);
  border-radius: 25px;
  font-size: 1rem;
  transition: background-color .5s ease, color .5s ease;
  &:hover{
    background-color: var(--primary);
  }
}

.text-center{
  text-align: center;
}

.text-underline{
  text-decoration: underline;
}

.text-uppercase{
  text-transform: uppercase;
}

.swiper-slide{
  width: 126px;
  height: 150px;
  & > :first-child{
    overflow: hidden;
    border-radius: 15px;
    transition: width .5s ease, height .5s ease, transform .5s ease;
    will-change: width, height, transform;
  }
  @media screen and (max-width: 992px) {
    height: 200px;
  }
}
.swiper-slide-active,
.swiper-slide-next{
  height: 208px;
  margin-inline-end: 24px;
  > :first-child{
    width: 172px !important;
    height: 100% !important;
    @media screen and (max-width: 992px) {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 992px) {
    height: 200px;
    margin-inline-end: 12px;
  }
  @media screen and (max-width: 768px) {
    margin-inline-end: 0;
  }
}

.swiper-slide-active{
  @media screen and (max-width: 992px) {
    transform: none !important;
  }
}

.swiper-slide-prev,
.swiper-slide-next + div{
  > :first-child{
    transform: translateY(80px);
    width: 145px !important;
    height: 175px !important;
    @media screen and (max-width: 992px) {
      transform: none !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.swiper-wrapper{
  margin-inline-start: -5rem;
  align-items: center;
}

.swiper-slide-prev{
  margin-inline-start: -1.2rem;
}

.swiper-button-next:after, .swiper-button-prev:after,
.swiper-button-next:after, .swiper-button-prev:after {
  content: none;
}

.swiper-button-next, .swiper-button-prev{
  width: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.swiper-button-next{
  background-image: url("../assets/arrow-right.svg");
}


.swiper-button-prev{
  background-image: url("../assets/arrow-left.svg");
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
  left: 0;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev{
  right: 0;
}

.linedTitle{
  position: relative;
  display: inline-block;
  margin-block-end: 2.5rem;
}

.lines{
  position: absolute;
  right: 0;
  bottom: -30px;
  display: block;
  width: 258px;
  height: 30px;
  background-image: url("../assets/lines-right.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.lines_left{
  background-image: url("../assets/line_left.png") !important;
  right: 20%;
}

.line-sm {
  display: block;
  width: 95px;
  height: 1px;
  background-color: #707070;
}

.fa-opensea{
  display: block;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/opensea.svg");
}

.privacy--image-container{
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 480px);
  background-color: #fff;
  @media screen and (max-width: 768px) {
    height: auto;
  }
  a{
    position: relative;
    display: block;
    flex: 0 0 50%;
    &::before{
      content: "Download";
      display: block;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      font-size: 32px;
      transition: opacity 0.5s ease;
      opacity: 0;
    }
    &:last-child{
      background-color: #000;
    }
    &:hover::before {
      opacity: 1;
    }
    img {
      width: 100%;
      height: 300px;
      object-fit: contain
    }
    @media screen and (max-width: 768px) {
      flex: 0 0 100%;
    }
  }
}

@media screen and (min-width: 576px) {
  .container{
    max-width: 569px;
  }
}

@media screen and (min-width: 768px) {
  .container{
    max-width: 765px;
  }
}

@media screen and (min-width: 992px) {
  .container{
    max-width: 989px;
  }
}

@media screen and (min-width: 1200px) {
  .container{
    max-width: 1199px;
  }
}

@media screen and (min-width: 1400px) {
  .container{
    max-width: 1395px;
  }
}

@media screen and (max-width: 1200px) {
  .swiper-button-next, .swiper-button-prev{
    width: 40px;
    height: 40px;
    top: 15%;
  }
}

@media screen and (max-width: 992px) {
  h1, .h1 {
    font-size: 3rem;
    line-height: 64px;
  }

  h2, .h2 {
    font-size: 2.25rem;
    line-height: 56px;
  }

  h3, .h3 {
    font-size: 1.5rem;
    line-height: 32px;
    font-weight: bold;
  }

  h4,.h4 {
    font-size: 1.25rem;
    line-height: 22px;
  }

  h6, .h6 {
    font-size: 1rem;
    line-height: 22px;
  }

  p{
    font-size: 1.25rem;
    line-height: 22px;
  }
}

@media screen and (max-width: 768px) {
  h1, .h1 {
    font-size: 2.25rem;
    line-height: 1.2;
  }

  h2, .h2 {
    font-size: 2rem;
    line-height: 1.2;
  }

  h3, .h3 {
    font-size: 1.3rem;
    line-height: 1.2;
    font-weight: bold;
  }

  h4,.h4 {
    font-size: 1.125rem;
    line-height: 1.2;
  }

  h6, .h6 {
    font-size: 1rem;
    line-height: 1.2;
  }

  p{
    font-size: 1rem;
    line-height: 1.2;
  }
}

