
.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  text-align: center;
  line-height: 58px;
  border: 1px solid #1D1D1D;
  color: var(--white);
  font-size: 16px;
  transition: background-color .4s ease;
  &:hover{
    background-color: var(--primary);
  }
  svg{
    width: 30px;
    height: 30px;
  }
}

.btn_white{

}