.portfolio_item{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E0E0E0;
  border-radius: 45px;
  overflow: hidden;
  padding: 0.9375rem;
}

.portfolio_title{
  display: block;
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 0.05rem;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.portfolio_image{
  position: relative;
  border-radius: 45px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
}

.portfolio_input_dots{
  position: relative;
  input{
    position: absolute;
    opacity: 0;
    width: 20px;
    height: 30px;
    cursor: pointer;
    &:checked{
      & + label {
        display: none;
      }
      & ~ div {
        display: flex;
      }
    }
  }
  label{
    position: relative;
    font-size: 2rem;
    line-height: 1;
    vertical-align: middle;
    color: #ACACAC;
    text-align: center;
    pointer-events: none;

  }
}

.portfolio_description{
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0%;
  z-index: 20;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #fff;
  color: #8A38E3;
}

.socials{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.social{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #EEEEEE;
  font-size: 1rem;
  text-align: center;
  border-radius: 10px;
  color: #A3A3A3;
  transition: all .3s ease;
  cursor: pointer;
  &:hover{
    background-color: #ECE0F5;
    color: var(--primary);
  }
  svg{
    width: 15px;
    height: 15px;
  }
}