.section{
  @media screen and (max-width: 992px) {
    min-height: auto;
  }
}

.container{
  display: flex;
  justify-content: space-between;
  padding-block-start: 10rem;
  h1 {
    span {
      bottom: 0;
    }
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    padding: 0 2rem;
    margin-block-start: 5rem;
    h1{
      margin: 0;
      span{
        bottom: -20%;
        right: auto;
        left: 0;
        @media screen and (max-width: 768px) {
          bottom: -40%;
        }
      }
    }
  }
}

.title{
  flex-shrink: 0;
  align-self: flex-start;
}

.content{
  max-width: 610px;
  width: 100%;
  span{
    margin: 5rem 0;
  }
  h3 {
    margin: 0;
  }
}

.content_white{
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  color: var(--black);
  margin-block-start: 11rem;
  padding: 5rem 7.8125rem;
  flex: 0 0 60%;
  &::after{
    content: '';
    display: block;
    width: 100%;
    max-width: 345px;
    height: 1px;
    background-color: var(--black);
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 2rem;
    margin-block-start: 2rem;
  }
}

.white_section{
  background-color: var(--white);
  color: var(--black);
  @media screen and (max-width: 992px) {
    h2{
      text-align: center;
    }
  }
}

.preference_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.preference_item{
  position: relative;
  flex: 0 0 16%;
  align-self: stretch;
  padding-block-end: 4.5rem;
  &::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -20%;
    width: 1px;
    height: 138px;
    background-color: #D3D3D3;
  }
  @media screen and (max-width: 992px) {
    & {
      flex: 0 0 33.3334%;
      padding: 0;
      text-align: center;
      &::before{
        bottom: 0;
        width: 100%;
        height: 2px;
      }
    }
  }
}

.preference_id{
  margin-block-end: 6rem;
  @media screen and (max-width: 992px) {
    margin-block-end: 3rem;
  }
}