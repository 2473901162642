.link_primary{
  display: block;
  max-width: 198px;
  height: 70px;
  line-height: 68px;
  background-color: transparent;
  border: 1px solid var(--btn-border);
  color: var(--black);
  border-radius: 25px;
  font-size: 1rem;
  transition: background-color .5s ease, color .5s ease;
  text-align: center;
  &:hover{
    background-color: var(--btn-border);
    color: var(--white);
  }
}

.link_dark{
  border-color: var(--black);
  background-color: var(--black);
  color: var(--white);
  &:hover{
    background-color: transparent;
    border-color: var(--btn-border);
    color: var(--white);
  }
}
